var templateObject = Object.freeze(["\n                    ", "\n                  "]);
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ranking"},[(this.$store.state.Auth.autStatus)?_c('PageHeader'):_vm._e(),_c('v-card',{attrs:{"background-color":"transparent","height":"100%"}},[_c('v-toolbar',{staticClass:"ma-0 pa-0",attrs:{"dense":"","color":"rgba(255, 255, 0, 1)","id":"header_f"}}),_c('v-tabs',{attrs:{"id":"tab","background-color":"transparent","grow":"","color":"rgba(255, 255, 0, 1)"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab,attrs:{"background-color":"transparent"}},[_vm._v(" "+_vm._s(tab)+" ")])}),1),_c('v-tabs-items',{attrs:{"background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"background-color":"transparent"}},[_c('v-card',{attrs:{"flat":"","background-color":"transparent"}},[_c('v-card-text',{attrs:{"background-color":"transparent"}},[_c('ApolloQuery',{ref:"globalRankingQuerry",attrs:{"query":function (gql) { return gql(templateObject, _vm.globalRankingQuerry); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var ref_result = ref.result;
                  var data = ref_result.data;
                  var error = ref_result.error;
                  var loading = ref_result.loading;
return [(loading)?_c('div',[_vm._v(_vm._s(_vm.$t("ranking.table.loading")))]):(data)?_c('RankingModule',{attrs:{"heightRanking":_vm.heightRaMod,"ranking":data.getBestTimeRanking}}):(error)?_c('div',[_vm._v(_vm._s(_vm.$t("ranking.table.error")))]):_c('div',[_vm._v(_vm._s(_vm.$t("ranking.table.noData")))])]}}])})],1)],1)],1),_vm._l((_vm.items),function(nb,nbName){return _c('v-tab-item',{key:nb,attrs:{"background-color":"transparent"}},[_c('v-card',{attrs:{"flat":"","background-color":"transparent"}},[_c('v-card-text',{attrs:{"background-color":"transparent"}},[_c('ApolloQuery',{ref:"dayRankingQuerry",refInFor:true,attrs:{"query":function (gql) { return gql(templateObject, _vm.dayRankingQuerry); },"variables":{ date: parseInt(nbName) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var ref_result = ref.result;
                  var data = ref_result.data;
                  var error = ref_result.error;
                  var loading = ref_result.loading;
return [(loading)?_c('div',[_vm._v(_vm._s(_vm.$t("ranking.table.loading")))]):(data)?_c('RankingModule',{attrs:{"heightRanking":_vm.heightRaMod,"ranking":data.getDayRanking}}):(error)?_c('div',[_vm._v(_vm._s(_vm.$t("ranking.table.error")))]):_c('div',[_vm._v(_vm._s(_vm.$t("ranking.table.noData")))])]}}],null,true)})],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }