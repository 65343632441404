<!-- TO DO
DIFFERENTIATE GLOBAL RANKING TO DAYLY RANKING -->
<template>
  <div class="rankingModule">
    <div style="display: none">{{ (i = 1) }}</div>
    <v-container fluid class="ma-0 pa-0">
      <v-simple-table dense fixed-header :height="heightRanking">
        <template v-slot:default>
          <thead>
            <tr height="40px">
              <th class="text-center font">
                <span class="font">{{ $t("ranking.table.rank") }}</span>
              </th>
              <th class="text-center font">
                <span class="font">{{ $t("ranking.table.player") }}</span>
              </th>
              <th class="text-center font">
                <span class="font">{{ $t("ranking.table.bestLap") }}</span>
              </th>
              <th class="text-center font">
                <span class="font">{{ $t("ranking.table.time") }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in ranking" :key="item.id" height="40px">
              <td class="text-center">
                <span class="font nonselection shadow">{{
                  rank(ranking, i)
                }}</span>
              </td>
              <td class="text-center">
                <span class="font nonselection shadow">{{ item.name }}</span>
              </td>
              <td class="text-center">
                <span class="font nonselection shadow">{{
                  timeConverstion(item.bestLap)
                }}</span>
              </td>
              <td class="text-center">
                <span class="font nonselection shadow">{{
                  timeConverstion(item.bestTime)
                }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["ranking", "rankingDay", "heightRanking"],
  async mounted() {},
  methods: {
    rank(rankingL, rank) {
      this.i++;
      if (this.i > rankingL.length) {
        this.i = 1;
      }
      return rank;
    },
    timeConverstion(time) {
      const MILLISECONDS_SECOND = 1000;
      const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND;

      var min = Math.floor(time / MILLISECONDS_MINUTE);
      var sec = Math.floor((time % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND);
      var msec = Math.floor(time % MILLISECONDS_SECOND);

      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      if (msec < 10) {
        msec = "00" + msec;
      } else if (msec < 100) {
        msec = "0" + msec;
      }

      return min + ":" + sec + "." + msec;
    },
  },
};
</script>

<style scoped>
@import "../assets/css/components/RankingModule.css";
</style>
