<!-- TO DO
- LOAD CORRECT DATA FOR EACH RANKING
- ONLOAD START AS DEFAULT RANKING VIEW WITH THE RIGHT DAYLY RANKING -->

<template>
  <div class="ranking">
    <PageHeader v-if="this.$store.state.Auth.autStatus" />
    <v-card background-color="transparent" height="100%">
      <v-toolbar
        dense
        color="rgba(255, 255, 0, 1)"
        class="ma-0 pa-0"
        id="header_f"
      />
      <v-tabs
        id="tab"
        v-model="tab"
        background-color="transparent"
        grow
        color="rgba(255, 255, 0, 1)"
      >
        <v-tab v-for="tab in tabs" :key="tab" background-color="transparent">
          {{ tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" background-color="transparent">
        <v-tab-item background-color="transparent">
          <v-card flat background-color="transparent">
            <v-card-text background-color="transparent">
              <ApolloQuery
                ref="globalRankingQuerry"
                :query="
                  (gql) =>
                    gql`
                      ${globalRankingQuerry}
                    `
                "
              >
                <template v-slot="{ result: { data, error, loading } }">
                  <div v-if="loading">{{ $t("ranking.table.loading") }}</div>
                  <RankingModule
                    v-else-if="data"
                    :heightRanking="heightRaMod"
                    :ranking="data.getBestTimeRanking"
                  />
                  <div v-else-if="error">{{ $t("ranking.table.error") }}</div>
                  <div v-else>{{ $t("ranking.table.noData") }}</div>
                </template>
              </ApolloQuery>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-for="(nb, nbName) in items"
          :key="nb"
          background-color="transparent"
        >
          <v-card flat background-color="transparent">
            <v-card-text background-color="transparent">
              <ApolloQuery
                ref="dayRankingQuerry"
                :query="
                  (gql) =>
                    gql`
                      ${dayRankingQuerry}
                    `
                "
                :variables="{ date: parseInt(nbName) }"
              >
                <template v-slot="{ result: { data, error, loading } }">
                  <div v-if="loading">{{ $t("ranking.table.loading") }}</div>
                  <RankingModule
                    v-else-if="data"
                    :heightRanking="heightRaMod"
                    :ranking="data.getDayRanking"
                  />
                  <div v-else-if="error">{{ $t("ranking.table.error") }}</div>
                  <div v-else>{{ $t("ranking.table.noData") }}</div>
                </template>
              </ApolloQuery>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import RankingModule from "@/components/RankingModule.vue";
import PageHeader from "@/components/Header.vue";
export default {
  data() {
    return {
      key: 0,
      participant: null,
      tab: null,
      date: null,
      heightRaMod: null,
      tabs: {
        0: this.$t("ranking.page.global"),
      },
      items: {
        20220228: "Day 1",
        20220301: "Day 2",
        20220302: "Day 3",
        20220303: "Day 4",
      },
      dayRankingQuerry: `
      query Query($date: Int!) {
        getDayRanking(date: $date) {
          name
          bestLap
          bestTime
        }
      }
      `,
      globalRankingQuerry: `
      query Query {
        getBestTimeRanking {
          id
          name
          dayRank
          globalRank
          bestLap
          bestTime
        }
      }
      `,
    };
  },
  components: {
    RankingModule,
    PageHeader,
  },
  mounted() {
    // var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    var height = window.innerHeight; // || document.documentElement.clientHeight || document.body.clientHeight
    this.heightRaMod =
      height -
      document.querySelector("#header_f").offsetHeight -
      document.querySelector("#tab").offsetHeight -
      32;
    let date = new Date();
    const day = ("0" + date.getUTCDate()).slice(-2);
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const year = date.getUTCFullYear();
    date = parseInt(`${year}${month}${day}`);
    var initTab = this.datekeyIndex(date);
    this.tab = initTab;
    console.log("mounted");
    return this.tab;
  },
  methods: {
    datekeyIndex(date) {
      if (Object.prototype.hasOwnProperty.call(this.items, date)) {
        const tab = Object.keys(this.items).findIndex(
          (b) => parseInt(b) === date
        );
        console.log("tab is = ", parseInt(tab) + 1);
        return parseInt(tab) + 1;
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/views/Ranking.css";
</style>
